'use strict';

var mobileNav = function () {

  var links = document.getElementsByClassName('js-nav');

  for (var i=0; i<links.length; i++) {
    links[i].onclick = function() {
      this.classList.toggle("c-main-nav__link--selected");
      this.nextElementSibling.classList.toggle("c-main-nav__dropdown--expanded");
      return false;
    }
  }
};

module.exports = mobileNav;
