'use strict';

var Cookies = require('js-cookie');

var cookies = function () {

  var cookie = Cookies.get('cookieAgree'),
      cookieAlert = document.querySelector('.c-cookie-alert'),
      cookieAlertButton = cookieAlert.querySelector('.c-cookie-alert button');

  cookieAlertButton.onclick = function() {
    Cookies.set('cookieAgree', 'CookieSet', { expires: 365 });
    cookieAlert.classList.remove('c-cookie-alert--on');
    console.log('ustawione');
  }

  if ( "undefined" === typeof cookie ) {
    // show cookie alert
    cookieAlert.classList.add('c-cookie-alert--on');
  } else {
    // hide cookie alert (hidden by default); nothing to do here
  }
};

module.exports = cookies;
