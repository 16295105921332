'use strict';

var GoogleFonts = function () {
  var WebFont = require('webfontloader');
  WebFont.load({
    google: {
      families: ['Asap+Condensed:400,700:latin-ext', 'Asap:400,500,600,700:latin-ext', 'PT+Sans:400,400i,700:latin-ext', 'Lato:400,500,600,700:latin-ext']
    }
  });
}
module.exports = GoogleFonts;
