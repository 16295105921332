'use strict';

var clickable = function () {
  var $ = require('jquery');
  $('.js-clickable').click(function(){
    var href = $(this).find('a').attr('href');
    window.location.href = href;
  });
};

module.exports = clickable;
