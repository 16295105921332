'use strict';

require('jquery-debouncedresize');

var breakpoint = {};

breakpoint.refreshValue = function () {
  this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
};
breakpoint.refreshValue();

var secondaryNav = function () {

  if ( breakpoint.value == 'xs' || breakpoint.value == 'sm' ) {
    var secondaryNav = document.querySelector(".c-secondary-nav");

    if( secondaryNav !== null && secondaryNav.childNodes !== null ) {
      var selectedElement = document.querySelector(".c-secondary-nav .current_page_item a");
      if ( selectedElement === null ) {
        selectedElement = document.querySelector(".c-secondary-nav li:first-child a");
      }
      var dropdownELement = document.querySelector(".js-dropdown");
      var height = selectedElement.offsetHeight;
      var topPos = selectedElement.offsetTop + selectedElement.style.paddingTop;

      secondaryNav.style.top = "-" + topPos + "px";
      dropdownELement.style.maxHeight = height + 'px';

      dropdownELement.onclick = function() {
        this.classList.toggle("c-dropdown--expanded");
        return false;
      }

    }

  }

};

module.exports = secondaryNav;
