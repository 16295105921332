'use strict';

var leki = function () {
  var $ = require('jquery');

  $('#dodaj').on('click', function(e){
      var n = $('#lek').clone().removeClass('error');
      var link = $('<a href="#" class="usun">usuń</a>');
      link.appendTo(n);
      n.appendTo('#duplicate-content');
      n.find('input').val('');
      e.preventDefault();
  });

  $('#form-leki').on('click', '.usun', function(e){
      $(this).parent().remove();
      e.preventDefault();
  });

  $('input[name=odbior]').on('change', function(){
    if( $(this).val() == 'osobiscie' ) {
      $('#upowazniony').fadeOut('fast');
    }
    if( $(this).val() == 'osoba-upowazniona' ) {
      $('#upowazniony').fadeIn();
    }
  });

  if($('#upowaznienie:checked').length > 0) {
    $('#upowazniony').fadeIn(0);
  }

};

module.exports = leki;
