/*
  Project: Lomzynska
  Author: Krzysztof Malina
*/
/*
window.onerror = function (msg, url, lineNo, columnNo, error) {
  alert(msg);
  return false;
}
*/

var $ = require('jquery');
window.jQuery = window.$ = $; // You expose jQ as a global object

var cookies = require('./cookies.js');
var googleFonts = require('./googlefonts.js');
var mobileNav = require('./mobilenav.js');
var heights = require('./heights.js');
//var detectFlexbox = require('./detectflexbox.js');
var externalLinks = require('./external-links.js');
var secondaryNav = require('./secondary-nav.js');
var taps = require('./taps.js');
var clickable = require('./clickable.js');
var leki = require('./leki.js');
var Tooltip = require('./tooltip.js');

googleFonts();
//detectFlexbox();
externalLinks();
heights();
secondaryNav();
//animateTestimonials();
mobileNav();
taps();
clickable();
leki();
cookies();
Tooltip();
